import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Swal from "sweetalert2";

import VueI18n from "vue-i18n";
// import locales from "./plugins/locales";
import locales from "../public/locales";

import VueGtm from "vue-gtm";

Vue.config.productionTip = false;

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "tw", // set locale
  messages: locales // set locale messages
});

Vue.prototype.$swal = Swal;
window._ = require("lodash");

// 初始化 VueGtm
Vue.use(VueGtm, {
  id: "GTM-K8SPGJJ", // GTM ID
  vueRouter: router, // 自動監聽 Vue Router
  enabled: true, // 是否啟用（可以用於開發環境控制）
  debug: false, // 是否開啟除錯模式
  loadScript: true // 自動載入 GTM 腳本
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: function (h) {
    return h(App);
  }
}).$mount("#app");
