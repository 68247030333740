import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"mt-12 mb-16"},[_c(VRow,{staticClass:"mt-12 mb-16"},[_c(VCol,{attrs:{"cols":"10","md":"6","offset":"1","offset-md":"3"}},[_c(VCard,{staticClass:"d-flex flex-column align-center py-6 mt-2",attrs:{"color":"orange lighten-1","dark":"","elevation":"3","shaped":"","href":"https://www.surveycake.com/s/lo3K3","target":"_blank"}},[_c(VCardTitle,{staticClass:"text-h5 font-weight-bold"},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"mb-2"},[_vm._v("佑昇診所")]),_c('div',[_vm._v("就診滿意度與意見回饋")])])]),_c(VCardTitle,{staticClass:"mt-1 text-h6"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("開始填寫 ")]),_c(VIcon,[_vm._v("mdi-pencil")])],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }