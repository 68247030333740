<template lang="pug">
  v-container.mt-12.mb-16
    v-row.mt-12.mb-16
      v-col(cols="10" md="6" offset="1" offset-md="3")
        v-card.d-flex.flex-column.align-center.py-6.mt-2(color="orange lighten-1" dark elevation="3" shaped href="https://www.surveycake.com/s/lo3K3" target="_blank")
          v-card-title.text-h5.font-weight-bold 
            .d-flex.flex-column.align-center
              div.mb-2 佑昇診所
              div 就診滿意度與意見回饋
          v-card-title.mt-1.text-h6
            .d-flex.align-center
              span.font-weight-bold.mr-2 開始填寫 
              v-icon mdi-pencil
</template>

<script>
export default {
  name: "WeCare"
};
</script>
